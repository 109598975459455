<template>
  <DatePicker
    v-model="inputValue"
    locale="pl"
    :masks="{ input: 'DD.MM.YYYY' }"
    :max-date="maxDate ? maxDate : ''"
    :min-date="minDate ? minDate : ''"
    :color="'teal'"
    is-required
    class="date-picker"
  >
    <template #default="{ inputValue: v, inputEvents }">
      <div
        class="form-field"
        :class="{ 'form-field--error': !!errorMessage, success: meta.valid }"
      >
        <label
          class="form-field__label"
          :for="name"
        >{{ props.label }}:</label>
        <input
          class="form-field__input"
          :value="v"
          @input="inputEvents.input"
          @change="inputEvents.change"
          @keyup="inputEvents.keyup"
          @mousemove="inputEvents.mousemove"
          @mouseleave="inputEvents.mouseleave"
          @focusin="inputEvents.focusin"
          @focusout="inputEvents.focusout"
        >
        <p
          v-show="errorMessage"
          class="form-field__error"
        >
          {{ errorMessage }}
        </p>
        <BaseIcon
          v-if="errorMessage"
          class="form-field__icon"
          type="warning"
          size="18"
          name="error"
        />
      </div>
    </template>
  </DatePicker>
</template>

<script lang="ts" setup>
// @ts-expect-error - ignore TS error
import { DatePicker } from 'v-calendar'
import { useField } from 'vee-validate'

import BaseIcon from '@/components/BaseIcon.vue'

const props = defineProps({
  value: {
    type: String
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    default: ''
  },
  forceFocus: {
    type: Boolean,
    default: false
  },
  maxDate: {
    type: Date,
    required: false
  },
  minDate: {
    type: Date,
    required: false
  }
})

const {
  value: inputValue,
  errorMessage,
  meta
} = useField<Date>(() => props.name, undefined, {
  label: props.label
})
</script>
<style>
.date-picker button {
  background-color: white;
}

.vc-nav-item,
.vc-nav-arrow,
.vc-nav-title {
  background-color: white;
}
</style>
