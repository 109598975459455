import type { Config } from '@/services/apiService'

import { api } from '@/services/apiService'
import type { DashboardState } from '@/store/types'

export type AddPatient = {
  birth_date: string
  email?: string
  gender: number
  name: string
  pattern_name: string
}

export const dashboardService = {
  fetchStats: () => {
    return api.get<DashboardState['userDashboard']>('/dashboard/')
  },
  fetchPatientsOptions: () => {
    return api.options('/dietitian/')
  },
  addPatient: (data: AddPatient, config?: Config) => {
    return api.post<{ patient_tab_available: boolean; url: string }>('/dietitian/', data, config)
  },
  addDiet: async (data: { group: number | null; name: string }, config?: Config) => {
    return api.post<{ id: string }>(
      '/diets/user/',
      {
        data: { name: data.name },
        name: data.name,
        group: data.group
      },
      config
    )
  }
}
