<template>
  <div class="extended-filters">
    <div class="row-f numbers">
      <div class="filter">
        <span>Kcal od (min):</span>
        <BaseInputNumber
          class="filter-number"
          :min="kcalMin"
          :max="kcalMax"
          :precision="1"
          :step="0.1"
          :model-value="kcalMinValue"
          @update:model-value="setKcalMin"
        />
      </div>
      <div class="filter">
        <span>Kcal do (max):</span>
        <BaseInputNumber
          class="filter-number"
          :min="kcalMin"
          :max="kcalMax"
          :precision="1"
          :step="0.1"
          :model-value="kcalMaxValue"
          @update:model-value="setKcalMax"
        />
      </div>
      <div class="filter">
        <span>Czas przygotowania od:</span>
        <BaseInputNumber
          class="filter-number"
          :min="preparationTimeMin"
          :max="preparationTimeMax"
          :precision="1"
          :step="0.1"
          :model-value="preparationTimeMinValue"
          @update:model-value="setPreparationTimeMin"
        />
      </div>
      <div class="filter">
        <span>Czas przygotowania do:</span>
        <BaseInputNumber
          class="filter-number"
          :model-value="preparationTimeMaxValue"
          :min="preparationTimeMin"
          :max="preparationTimeMax"
          :precision="1"
          :step="0.1"
          @update:model-value="setPreparationTimeMax"
        />
      </div>
      <div class="filter">
        <span>Porcje od:</span>
        <BaseInputNumber
          class="filter-number"
          :model-value="portionsMinValue"
          :min="portionsMin"
          :max="portionsMax"
          :precision="1"
          :step="0.1"
          @update:model-value="setPortionsMin"
        />
      </div>
      <div class="filter">
        <span>Porcje do:</span>
        <BaseInputNumber
          class="filter-number"
          :model-value="portionsMaxValue"
          :min="portionsMin"
          :max="portionsMax"
          :precision="1"
          :step="0.1"
          @update:model-value="setPortionsMax"
        />
      </div>
    </div>
    <div class="filter-box size-box">
      <span>Rozmiar potrawy</span>
      <div class="row-f size-types">
        <BaseCheckbox
          v-for="size in sizeChoices"
          :key="size.value"
          :label="size.text"
          :model-value="size.active"
          @update:model-value="setDishSize(size.value)"
        />
      </div>
    </div>
    <div class="filter-box meal-type-box">
      <span>Typ posiłku</span>
      <div class="row-f meal-types">
        <BaseCheckbox
          v-for="mealType in mealTypeChoices"
          :key="mealType.value"
          :disabled="!dishesWithIngredientsLoaded"
          :label="mealType.text"
          :model-value="mealType.active"
          @update:model-value="setMealType(mealType.value)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'

export type Choice = {
  active: boolean
  text: string
  value: number
}

export type IngredientBaseChoice = {
  active: boolean
  disabled: boolean
  text: string
  value: number
}
const props = defineProps({
  dishesWithIngredientsLoaded: {
    type: Boolean,
    required: true
  },
  kcalMin: {
    type: Number,
    required: true
  },
  kcalMax: {
    type: Number,
    required: true
  },
  preparationTimeMin: {
    type: Number,
    required: true
  },
  preparationTimeMax: {
    type: Number,
    required: true
  },
  portionsMin: {
    type: Number,
    required: true
  },
  portionsMax: {
    type: Number,
    required: true
  },
  activeTab: {
    type: String,
    required: true
  },
  resetFilters: {
    type: Number,
    required: true
  }
})
const {
  dishesWithIngredientsLoaded,
  kcalMax,
  kcalMin,
  preparationTimeMax,
  preparationTimeMin,
  portionsMax,
  portionsMin,
  activeTab,
  resetFilters
} = toRefs(props)
const emit = defineEmits([
  'setDishSize',
  'setMealType',
  'setKcalMin',
  'setKcalMax',
  'setPreparationTimeMin',
  'setPreparationTimeMax',
  'setPortionsMin',
  'setPortionsMax',
  'resetKcalMinMaxValue',
  'resetPreparationTimeMinMaxValue',
  'resetPortionsMinMaxValue'
])
const sizeValues = ref<number[]>([])
const mealTypeValues = ref<number[]>([])
const kcalMinValue = ref(props.kcalMin)
const kcalMaxValue = ref(props.kcalMax)
const preparationTimeMinValue = ref(props.preparationTimeMin)
const preparationTimeMaxValue = ref(props.preparationTimeMax)
const portionsMinValue = ref(props.portionsMin)
const portionsMaxValue = ref(props.portionsMax)

const sizeChoices = computed<Choice[]>(() => {
  return [
    {
      value: 1,
      text: 'Mały',
      active: false
    },
    {
      value: 2,
      text: 'Średni',
      active: false
    },
    {
      value: 3,
      text: 'Duży',
      active: false
    }
  ].map((choice) => {
    choice.active = sizeValues.value.includes(choice.value)
    return choice
  })
})
const mealTypeChoices = computed<Choice[]>(() => {
  return [
    {
      value: 1,
      text: 'Śniadanie',
      active: false
    },
    {
      value: 2,
      text: 'Drugie śniadanie',
      active: false
    },
    {
      value: 3,
      text: 'Obiad',
      active: false
    },
    {
      value: 4,
      text: 'Podwieczorek',
      active: false
    },
    {
      value: 5,
      text: 'Kolacja',
      active: false
    },
    {
      value: 6,
      text: 'Przekąska',
      active: false
    }
  ].map((choice) => {
    choice.active = mealTypeValues.value.includes(choice.value)
    return choice
  })
})

const setDishSize = (value: number) => {
  addOrRemoveFromList(sizeValues.value, value)
  emit('setDishSize', sizeValues.value)
}
const setMealType = (value: number) => {
  addOrRemoveFromList(mealTypeValues.value, value)
  emit('setMealType', mealTypeValues.value)
}
const setKcalMin = (value: number) => {
  kcalMinValue.value = value
  emit('setKcalMin', value)
}
const setKcalMax = (value: number) => {
  kcalMaxValue.value = value
  emit('setKcalMax', value)
}
const setPreparationTimeMin = (value: number) => {
  preparationTimeMinValue.value = value
  emit('setPreparationTimeMin', value)
}
const setPreparationTimeMax = (value: number) => {
  preparationTimeMaxValue.value = value
  emit('setPreparationTimeMax', value)
}
const setPortionsMin = (value: number) => {
  portionsMinValue.value = value
  emit('setPortionsMin', value)
}
const setPortionsMax = (value: number) => {
  portionsMaxValue.value = value
  emit('setPortionsMax', value)
}

const addOrRemoveFromList = <T>(valuesList: T[], value: T) => {
  const index = valuesList.indexOf(value)
  if (index === -1) {
    valuesList.push(value)
  } else {
    valuesList.splice(index, 1)
  }
}

const resetExtendedFilters = () => {
  mealTypeValues.value = []
  sizeValues.value = []
  kcalMinValue.value = kcalMin.value
  kcalMaxValue.value = kcalMax.value
  preparationTimeMinValue.value = preparationTimeMin.value
  preparationTimeMaxValue.value = preparationTimeMax.value
  portionsMinValue.value = portionsMin.value
  portionsMaxValue.value = portionsMax.value
  emit('resetKcalMinMaxValue')
  emit('resetPreparationTimeMinMaxValue')
  emit('resetPortionsMinMaxValue')
}

watch(activeTab, () => {
  resetExtendedFilters()
})
watch(resetFilters, () => {
  resetExtendedFilters()
})
watch(kcalMin, (value) => {
  kcalMinValue.value = value
})
watch(kcalMax, (value) => {
  kcalMaxValue.value = value
})
watch(preparationTimeMin, (value) => {
  preparationTimeMinValue.value = value
})
watch(preparationTimeMax, (value) => {
  preparationTimeMaxValue.value = value
})
watch(portionsMin, (value) => {
  portionsMinValue.value = value
})
watch(portionsMax, (value) => {
  portionsMaxValue.value = value
})
</script>

<style>
.extended-filters {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: 30px;
}

.extended-filters .row-f.calories {
  margin-bottom: 23px;
  column-gap: 16px;
  justify-content: flex-start;
}

.extended-filters .row-f {
  display: flex;
}

.extended-filters .row-f.numbers {
  justify-content: space-between;
}

.extended-filters .row-f.meal-types,
.extended-filters .row-f.size-types {
  margin-top: 12px;
  margin-bottom: 24px;
}

.extended-filters .row-f.meal-types .base-checkbox,
.extended-filters .row-f.size-types .base-checkbox {
  margin-right: 16px;
}

.extended-filters .row-f.calories,
.extended-filters .row-f.preparation-time,
.extended-filters .row-f.portions {
  column-gap: 25px;
}

.extended-filters .filter-box {
  display: flex;
  flex-direction: column;
}

.extended-filters .filter-box .filter-box-wrapped {
  display: flex;
  flex-flow: column wrap;
  max-height: 250px;
  column-gap: 49px;
}

.extended-filters .filter-box span,
.extended-filters .filter span {
  font-size: 14px;
  font-family: Montserrat-Medium;
}

.extended-filters .filter .base-input {
  width: 54px;
  text-align: center;
}

.row-f .filter {
  min-height: 45px;
}

.row-f.numbers .filter {
  width: calc(100% / 6);
}

.ingredient-base-row {
  display: flex;
}

.ingredient-base-row .unlock {
  color: var(--color-destructive-100);
  font-size: 8px;
  font-family: Montserrat-SemiBold;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--color-destructive-100);
  padding: 2px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;
}
</style>
