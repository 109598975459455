<template>
  <DatePicker
    v-model="model"
    locale="pl"
    :masks="{ input: 'DD.MM.YYYY' }"
    :max-date="maxDate ? maxDate : ''"
    :min-date="minDate ? minDate : ''"
    :color="'teal'"
    is-required
    class="date-picker"
  >
    <template #default="{ inputValue, inputEvents }">
      <input
        :data-test-id="`${props.testId}__BaseInputDate`"
        class="base-input date-input"
        :value="inputValue"
        @input="inputEvents.input"
        @change="inputEvents.change"
        @keyup="inputEvents.keyup"
        @mousemove="inputEvents.mousemove"
        @mouseleave="inputEvents.mouseleave"
        @focusin="inputEvents.focusin"
        @focusout="inputEvents.focusout"
      >
    </template>
  </DatePicker>
</template>

<script lang="ts" setup>
import { format, parse } from 'date-fns'
// @ts-expect-error - ignore TS error
import { DatePicker } from 'v-calendar'
import { computed } from 'vue'

const props = defineProps({
  testId: {
    required: true,
    type: String
  },
  modelValue: {
    required: true,
    type: String
  },
  maxDate: {
    required: false,
    type: Number
  },
  minDate: {
    required: false,
    type: Number
  }
})
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    if (props.modelValue.includes('-')) {
      return parse(props.modelValue, 'dd-MM-yyyy', new Date())
    } else if (props.modelValue.includes('.')) {
      return parse(props.modelValue, 'dd.MM.yyyy', new Date())
    } else {
      return new Date()
    }
  },
  set(value) {
    emit('update:modelValue', format(new Date(value), 'dd-MM-yyyy'))
  }
})
</script>

<style scoped>
.date-input {
  text-align: center;
}
</style>
