<template>
  <div
    v-cloak
    class="diet-detail"
  >
    <template v-if="isMounted && (dietType === 'patient' || dietSaveStatus !== 'initialized')">
      <TeleportComponent to="#app-content-header-portal">
        <div class="diet-detail-change-week-save">
          <div
            v-if="dietType === 'patient'"
            class="diet-detail-change-week"
          >
            <BaseTooltip class="base-tooltip--bottom base-tooltip--center">
              <template #tooltip>
                Poprzedni tydzień
              </template>
              <IconButton
                name="keyboard_arrow_left"
                type="close"
                @click="goToPrevDiet"
              />
            </BaseTooltip>
            <span class="dates">{{ changeWeekStartDate }} / {{ changeWeekEndDate }}</span>
            <BaseTooltip class="base-tooltip--bottom base-tooltip--center">
              <template #tooltip>
                Następny tydzień
              </template>
              <IconButton
                name="keyboard_arrow_right"
                type="close"
                @click="goToNextDiet"
              />
            </BaseTooltip>
          </div>
          <SavingStatus
            :status="dietSaveStatus"
            type="diet"
          />
        </div>
      </TeleportComponent>
    </template>
    <div class="info">
      <BaseInfo
        v-if="showAllergens"
        @close="updateAllergensClosedActive(true)"
      >
        <template #icon>
          <BaseIcon
            size="20"
            type="info"
            name="warning"
          />
        </template>
        <div class="allergy-text">
          Jadłospis zawiera produkt, na który Twój pacjent ma alergię, nietolerancję lub nie lubi
          produktu którego użyłeś
        </div>
        <div class="allergy-info">
          <div class="allergy-info__element">
            <div class="allergy-info__square notlike" />
            nielubiane
          </div>
          <div class="allergy-info__element">
            <div class="allergy-info__square like" />
            lubiane
          </div>
          <div class="allergy-info__element">
            <div class="allergy-info__square allergy" />
            alergia
          </div>
          <div class="allergy-info__element">
            <div class="allergy-info__square intolerance" />
            nietolerancja
          </div>
        </div>
      </BaseInfo>
      <BaseInfo
        v-if="showIncompleteData"
        @close="handleIncompleteDataInfoClosed(true)"
      >
        <template #icon>
          <BaseIcon
            size="20"
            type="info"
            name="info"
          />
        </template>
        Twój jadłospis zawiera produkty z niepełnymi danymi odnośnie wartości odżywczych
      </BaseInfo>
      <BaseInfo
        v-if="showLosses"
        @close="handleLossesClosed(true)"
      >
        <template #icon>
          <BaseIcon
            size="20"
            name="delete_sweep"
          />
        </template>
        W jadłospisie uwzględniono straty talerzowe (sprawdź w zakładce założenia diety)
      </BaseInfo>
    </div>
    <div class="nav-actions">
      <div class="nav">
        <router-link
          class="nav-element"
          :to="{
            name: 'DietDetailWeek',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Grafik
        </router-link>
        <router-link
          class="nav-element"
          :to="{
            name: 'DietDetailShoppingList',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Lista zakupów
        </router-link>
        <router-link
          class="nav-element"
          :to="{
            name: 'DietDetailComments',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Komentarze
        </router-link>
        <router-link
          v-if="dietType !== 'system'"
          class="nav-element"
          :to="{
            name: 'DietDetailSettings',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Opis i właściwości
        </router-link>
        <router-link
          v-if="dietType !== 'system'"
          class="nav-element"
          :to="{
            name: 'DietDetailAssumptions',
            params: {
              dietType: dietType,
              dietId: route.params.dietId
            }
          }"
        >
          Założenia diety
        </router-link>
      </div>
      <div class="actions">
        <!-- Undo -->
        <BaseTooltip
          v-if="undoRedoEnabled"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            :disabled="undoHistory.length <= 0"
            name="undo"
            @click="undoHandler"
          />
          <template #tooltip>
            Cofnij akcję (maks. 5)
          </template>
        </BaseTooltip>
        <!-- Redo -->
        <BaseTooltip
          v-if="undoRedoEnabled"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            :disabled="redoHistory.length <= 0"
            name="redo"
            @click="redoHandler"
          />
          <template #tooltip>
            Ponów akcję (maks. 5)
          </template>
        </BaseTooltip>
        <div
          v-if="undoRedoEnabled"
          class="actions-separator"
        />
        <!-- Assign to leader -->
        <BaseTooltip
          v-if="canAssignDietToLeader"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="school"
            @click="showAssignToLeaderModal"
          />
          <template #tooltip>
            Przypisz jadłospis do Prowadzącego
          </template>
        </BaseTooltip>
        <DietAssignToLeaderModal
          v-if="assignToLeaderModal && canAssignDietToLeader"
          :diet-type="dietType"
          :diets-ids="[dietId]"
          @close="hideAssignToLeaderModal"
        />
        <!-- Assign to patient -->
        <BaseTooltip
          v-if="dietType !== 'university'"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="person_add"
            @click="showAssignToPatientModal"
          />
          <template #tooltip>
            Przypisz jadłospis do pacjenta
          </template>
        </BaseTooltip>
        <DietAssignToPatientModal
          v-if="assignToPatientModal"
          :diet-type="dietType"
          :diet-id="dietId"
          @close="hideAssignToPatientModal"
          @send-system-diets-list-to-file="sendSystemDietsListToFile"
        />
        <!-- Repeated dishes -->
        <BaseTooltip
          v-if="canRepeatedDishesAndIngredients"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            :type="repeatedDishesAndIngredientsActive ? 'warning' : 'default'"
            name="assignment_turned_in"
            @click="repeatedDishesHandler"
          />
          <template #tooltip>
            Włącz wyświetlanie potraw powtórzonych w innych jadłospisach tego pacjenta
          </template>
        </BaseTooltip>
        <!-- Copy diet -->
        <BaseLoader
          v-show="copyingDiet"
          class="base-loader--size-20 copy-diet-loader"
        />
        <BaseTooltip
          v-if="canCopyDiet"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="content_copy"
            @click="copyDietHandler"
          />
          <template #tooltip>
            Skopiuj jadłospis do schowka
          </template>
        </BaseTooltip>
        <!-- Paste diet -->
        <BaseTooltip
          v-else-if="canPasteDiet"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="content_paste"
            @click="showPasteDietModal"
          />
          <template #tooltip>
            Wklej jadłospis ze schowka
          </template>
        </BaseTooltip>
        <BaseModal
          v-if="pasteDietModal"
          @close="hidePasteDietModal"
        >
          <template #title>
            Czy na pewno chcesz wkleić skopiowany jadłospis?
          </template>
          <div class="paste-diet-modal__text">
            Wklejenie jadłospisu usunie aktualnie dodane potrawy.
          </div>
          <div
            v-if="currentMealsCount !== print.copiedMealsCount"
            class="meals-count-info"
          >
            <strong>Liczba posiłków w obecnym jadłospisie: {{ currentMealsCount }}</strong>
            <strong>Liczba posiłków w skopiowanym jadłospisie: {{ print.copiedMealsCount }}</strong>
          </div>
          <div class="paste-diet-modal__scale-form">
            <div class="paste-diet-modal__text">
              Podaj nową kaloryczność jeżeli chcesz przeskalować jadłospis:
            </div>
            <div class="input-container">
              <BaseInputNumber
                v-if="hasPerm('accounts.diet_copy_with_rescale')"
                v-model="pasteDietModalCalories"
                class="paste-diet-modal__input"
                :min="0"
                :max="9999"
                :precision="0"
                @input-status="pasteDietModalUpdateStatus = $event"
              />
              <div
                v-else
                class="input-dummy"
                @click="showAppUpgradePlanModal"
              >
                {{ pasteDietModalCalories }}
              </div>
            </div>
          </div>
          <template #actions>
            <StyledButton
              class="paste-diet-modal-clear-storage-button"
              variant="outline"
              @click.once="pasteDietModalClearStorage"
            >
              Wyczyść schowek
            </StyledButton>
            <StyledButton
              variant="outline"
              @click.once="hidePasteDietModal"
            >
              Anuluj
            </StyledButton>
            <StyledButton
              :disabled="pasteDietModalUpdateStatus === 'inprogress'"
              @click.once="pasteDietModalConfirm"
            >
              Wklej
            </StyledButton>
          </template>
        </BaseModal>
        <BaseTooltip
          v-if="dietType === 'patient'"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            variant="outline"
            icon-variant="outline"
            icon-size="24"
            :name="isDietVisible ? 'visibility' : 'visibility_off'"
            :type="isDietVisible ? 'default' : 'disabled'"
            @click="isDietVisibleHandler"
          />

          <template
            v-if="isDietVisible"
            #tooltip
          >
            Jadłospis widoczny dla pacjenta
          </template>
          <template
            v-else
            #tooltip
          >
            Jadłospis niewidoczny dla pacjenta
          </template>
        </BaseTooltip>

        <!-- Print diet -->
        <BaseTooltip class="base-tooltip--top base-tooltip--right">
          <IconButton
            name="print"
            @click="showPrintDietModal"
          />
          <template #tooltip>
            Drukuj jadłospis
          </template>
        </BaseTooltip>
        <BaseModal
          v-if="printDietModal"
          @close="hidePrintDietModal"
          @click="deactivateSelects"
        >
          <template #title>
            Pobierz PDF
          </template>
          <div>Wybierz opcje poniżej by pokazać/ukryć elementy na swoim PDF:</div>
          <div>
            <div class="print-modal-box print-modal-theme">
              <label class="print-modal-title print-modal-theme-title">Wybierz szablon:</label>
              <BaseSelect
                v-model="print.theme"
                class="print-modal-theme-select"
                :options="printThemes"
              />
            </div>
            <div class="print-modal-box">
              <label class="print-modal-title">Grupuj po:</label>
              <div class="print-modal-inner-box">
                <div @click="checkPermissions">
                  <BaseRadio
                    :label="'Dniach'"
                    :disabled="!printConfig"
                    :model-value="print.groupBy === 'days'"
                    @update:model-value="print.groupBy = 'days'"
                  />
                </div>
                <div @click="checkPermissions">
                  <BaseRadio
                    :label="'Posiłkach'"
                    :disabled="!printConfig"
                    :model-value="print.groupBy === 'meals'"
                    @update:model-value="print.groupBy = 'meals'"
                  />
                </div>
              </div>
            </div>
            <div class="print-modal-box">
              <label class="print-modal-title">Sortuj po:</label>
              <div class="print-modal-inner-box">
                <div @click="checkPermissions">
                  <BaseRadio
                    :label="'Nazwie'"
                    :disabled="!printConfig"
                    :model-value="print.sortBy === 'alphabetically'"
                    @update:model-value="print.sortBy = 'alphabetically'"
                  />
                </div>
                <div @click="checkPermissions">
                  <BaseRadio
                    :label="'Gramaturze'"
                    :disabled="!printConfig"
                    :model-value="print.sortBy === 'grams'"
                    @update:model-value="print.sortBy = 'grams'"
                  />
                </div>
              </div>
            </div>
            <div class="print-modal-box">
              <label class="print-modal-title">Pokaż/ukryj dni:</label>
              <div class="print-modal-inner-box">
                <div
                  class="days-container"
                  @click="checkPermissions"
                >
                  <BaseCheckbox
                    v-model="print.days.monday"
                    :label="'Pon'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.days.tuesday"
                    :label="'Wt'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.days.wednesday"
                    :label="'Śr'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.days.thursday"
                    :label="'Czw'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.days.friday"
                    :label="'Pt'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.days.saturday"
                    :label="'Sob'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.days.sunday"
                    :label="'Niedz'"
                    :disabled="!printConfig"
                  />
                </div>
              </div>
            </div>
            <div class="print-modal-box">
              <label class="print-modal-title">Pokaż/ukryj wybrane opcje:</label>
              <div
                class="print-modal-inner-box"
                @click="checkPermissions"
              >
                <div class="print-modal-inner-box-options">
                  <BaseCheckbox
                    v-model="print.showWeek"
                    :label="'Podsumowanie tygodniowe'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.showComments"
                    :label="'Komentarze'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.showShoppingList"
                    :label="'Lista zakupów'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.showPreparationTime"
                    :label="'Czas przygotowania'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.showQRCodes"
                    :label="'Kody QR do aplikacji'"
                    :disabled="!printConfig"
                  />
                </div>
                <div class="print-modal-inner-box-options">
                  <BaseCheckbox
                    v-model="print.printInevitableLosses"
                    :label="'Straty talerzowe'"
                    :disabled="!printConfig || !lossesActive"
                  />
                  <BaseCheckbox
                    v-model="print.showDate"
                    :label="'Daty'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.showMealHour"
                    :label="'Godziny posiłków'"
                    :disabled="!printConfig"
                  />
                  <BaseCheckbox
                    v-model="print.showNutrients"
                    :label="'Wartości odżywcze'"
                    :disabled="!printConfig"
                    @update:model-value="
                      print.showNutrients === false ? (print.showChosenNutrients = false) : ''
                    "
                  />
                  <BaseCheckbox
                    v-model="print.showChosenNutrients"
                    :label="'Wybrane wartości odżywcze'"
                    :disabled="!printConfig"
                    @update:model-value="
                      print.showChosenNutrients === true ? (print.showNutrients = true) : ''
                    "
                  />
                  <BaseCheckbox
                    v-model="print.showKcalmarLogo"
                    :label="'Stopka z logotypem Kcalmar i informacją o aplikacji'"
                    :disabled="!printConfig || !hasPerm('accounts.print_disable_footer')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="print-modal-box">
            <label class="print-modal-title">Inne:</label>
            <div @click="checkPermissions">
              <BaseCheckbox
                v-model="print.separateDaysOrMeals"
                :label="'Dni/posiłki na osobnych stronach'"
                :disabled="!printConfig"
              />
            </div>
          </div>
          <div
            v-if="testPrint"
            class="test-print-warning"
          >
            <span class="paragraph">Ta opcja jest niedostępna dla konta testowego. Pobierz przykładowy wydruk, wykup
              dostęp lub skorzystaj z poniższej opcji.</span>
            <span>Każda osoba może jednorazowo wykupić 5-dniowy pakiet Premium w cenie 12,29 zł w celu
              przetestowania zaawansowanych funkcji programu. W przypadku zainteresowania, prosimy o
              kontakt mailowy
              <a href="mailto:kontakt@kcalmar.com&subject=5 dni Premium">kontakt@kcalmar.com</a></span>
          </div>
          <template #actions>
            <div
              v-if="isLimitedPrintVersion"
              class="print-modal__diet-limit"
            >
              <p v-if="printDietLimit > 0">
                Pozostała liczba jadłospisów do wydrukowania w wersji akademickiej:
                {{ printDietLimit }}
              </p>
              <p
                v-else
                class="print-modal__diet-limit--warning"
              >
                Wykorzystałeś już limit wydrukowanych jadłospisów w wersji akademickiej, przejdź na
                wyższy plan.
              </p>
            </div>

            <StyledButton
              variant="outline"
              @click.once="hidePrintDietModal"
            >
              Anuluj
            </StyledButton>
            <StyledButton
              v-if="!printLimitExceeded && !testPrint"
              @click="
                !hasPerm('accounts.print_test') ||
                  hasPerm('accounts.print_full') ||
                  isLimitedPrintVersion
                  ? printDietModalConfirm()
                  : (testPrint = true)
              "
            >
              Pobierz
            </StyledButton>
            <StyledButton
              v-if="printLimitExceeded"
              type="error"
              @click="redirectToPlanChange"
            >
              Zmień plan
            </StyledButton>
            <StyledButton
              v-if="testPrint"
              @click="redirectToPlanChange"
            >
              Kup dostęp
            </StyledButton>
            <StyledButton
              v-if="testPrint"
              @click="printDietModalConfirm"
            >
              Przykładowy wydruk
            </StyledButton>
          </template>
        </BaseModal>
        <!-- Share diet -->
        <BaseTooltip
          v-if="canShareDiet"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="share"
            @click="showShareDietModal"
          />
          <template #tooltip>
            Udostępnij jadłospis innemu użytkownikowi
          </template>
        </BaseTooltip>
        <DietShareModal
          v-if="shareDietModal"
          :diet-type="dietType"
          :diets-ids="[dietId]"
          @close="hideShareDietModal"
        />
        <!-- Delete diet -->
        <BaseTooltip
          v-if="canDeleteDiet"
          class="base-tooltip--top base-tooltip--right"
        >
          <IconButton
            name="delete"
            type="destructive"
            variant="outline"
            @click="showDeleteDietModal"
          />
          <template #tooltip>
            Wyczyść jadłospis
          </template>
        </BaseTooltip>
        <BaseModal
          v-if="deleteDietModal"
          @close="hideDeleteDietModal"
        >
          <template #title>
            Czy na pewno chcesz wyczyścić cały tydzień?
          </template>
          Wyczyszczenie całego tygodnia usunie aktualnie dodane potrawy, produkty i komentarze z
          całego jadłospisu.
          <template #actions>
            <StyledButton
              variant="outline"
              @click.once="hideDeleteDietModal()"
            >
              Anuluj
            </StyledButton>
            <StyledButton @click.once="deleteDietModalConfirm">
              Wyczyść
            </StyledButton>
          </template>
        </BaseModal>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import type { DietType } from '@/store/types'
import type { Nutrients } from '@/utils/nutrients'
import type { AxiosError } from 'axios'
import type { DietitianPatient, StoreDiet } from '@/types/Diet'

import { A, D, G, pipe } from '@mobily/ts-belt'
import { useMounted } from '@vueuse/core'
import { addDays, format, parse, subDays } from 'date-fns'
import { decompressFromUTF16 } from 'lz-string'
import { storeToRefs } from 'pinia'

import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  provide,
  ref,
  Teleport as TeleportComponent,
  watch
} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseInfo from '@/components/BaseInfo.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseLoader from '@/components/BaseLoader.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseRadio from '@/components/BaseRadio.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import DietAssignToLeaderModal from '@/components/diets/DietAssignToLeaderModal.vue'
import DietAssignToPatientModal from '@/components/diets/DietAssignToPatientModal.vue'
import DietShareModal from '@/components/diets/DietShareModal.vue'
import { IconButton } from '@/components/IconButton'
import SavingStatus from '@/components/SavingStatus.vue'
import { StyledButton } from '@/components/StyledButton'
import { DEFAULT_NUTRIENTS } from '@/const'
import eventBus from '@/eventBus'
import { useSearchDishesAndIngredients } from '@/hooks/useSearchDishesAndIngredients'
import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { byDefault } from '@/utils/byDefault'
import { reportError } from '@/utils/reportError'
import { stringify } from '@/utils/stringify'

import { DietDetailInject } from './provider'

const globalStore = useGlobalStore()
const dietStore = useDietsStore()
const route = useRoute()
const router = useRouter()
const isMounted = useMounted()

const { hasPerm, showAppUpgradePlanModal, incrementPrintedDiets } = globalStore
const { user } = storeToRefs(globalStore)

const {
  undo,
  redo,
  resetDietsStore,
  updateDiet,
  copyDiet,
  pasteDiet,
  deleteDiet,
  deleteCopiedDiet,
  updateRepeatedDishesAndIngredients,
  toggleRepeatedDishesAndIngredientsActive,
  toggleIsDietVisible,
  updateLossesClosedActive,
  updateAllergensClosedActive,
  updateIncompleteDataInfoClosedActive
} = dietStore

const {
  diet,
  dietSaveStatus,
  incompleteDataInfo,
  incompleteDataInfoClosed,
  copiedDietStatus,
  copiedDietCalories,
  repeatedDishesAndIngredientsActive,
  undoHistory,
  redoHistory,
  allergensInfo,
  lossesClosed,
  allergensClosed,
  isDietVisible
} = storeToRefs(dietStore)

const dietId = ref(Number(route.params.dietId))
const dietType = ref(route.params.dietType as DietType)
const assignToLeaderModal = ref(false)
const assignToPatientModal = ref(false)
const pasteDietModal = ref(false)
const printDietModal = ref(false)
const shareDietModal = ref(false)
const deleteDietModal = ref(false)
const copyingDiet = ref(false)
const pasteDietModalCalories = ref(0)
const testPrint = ref(false)
const pasteDietModalUpdateStatus = ref('finished')
const print = ref({
  theme: 'kcalmar',
  groupBy: 'days',
  sortBy: 'grams',
  showWeek: true,
  showNutrients: true,
  showChosenNutrients: false,
  showPreparationTime: true,
  showKcalmarLogo: true,
  printInevitableLosses: false,
  showComments: true,
  showShoppingList: true,
  showDate: true,
  showMealHour: true,
  showQRCodes: true,
  separateDaysOrMeals: false,
  copiedMealsCount: 0,
  days: {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true
  }
})

const nutrientValue = ref<'choosen' | Nutrients>('choosen')
const nutrients = computed<Nutrients[]>(() => {
  if (diet.value && hasPerm('accounts.diet_microelements')) {
    if (nutrientValue.value === 'choosen') {
      return diet.value.data.choosen_nutrients
    }
  }
  return DEFAULT_NUTRIENTS
})
const {
  data: searchDishesAndIngredients,
  isPending: searchDishesAndIngredientsLoading,
  status: searchDishesAndIngredientsStatus,
  refetch: refetchSearchDishesAndIngredients
} = useSearchDishesAndIngredients(nutrients)

provide(DietDetailInject, {
  searchDishesAndIngredients,
  searchDishesAndIngredientsLoading,
  searchDishesAndIngredientsStatus,
  refetchSearchDishesAndIngredients,
  nutrientValue,
  nutrients
})

const undoRedoEnabled = computed(() => {
  if (route.name) {
    return (
      ['DietDetailWeek', 'DietDetailDay'].includes(route.name.toString()) &&
      dietType.value !== 'system'
    )
  }
  return false
})
const isLimitedPrintVersion = computed(() => {
  return (
    (user?.value?.permissions.includes('accounts.print_limited') &&
      !user.value.permissions.includes('accounts.print_full')) ??
    false
  )
})
const printDietLimit = computed(() => {
  const printedDiets = user?.value?.printed_diets ?? import.meta.env.VUE_APP_PRINTED_DIET_LIMIT
  return import.meta.env.VUE_APP_PRINTED_DIET_LIMIT - printedDiets
})
const printLimitExceeded = computed(() => {
  return isLimitedPrintVersion.value && printDietLimit.value <= 0
})
const currentMealsCount = computed(() => {
  return diet.value?.data.meals.length ?? 0
})

const isRodoAccepted = computed(() => {
  return user?.value?.is_rodo_accepted ?? false
})
const canAssignDietToLeader = computed(() => {
  return hasPerm('accounts.diet_assign_to_leader') && ['user', 'patient'].includes(dietType.value)
})
const printConfig = computed(() => {
  return hasPerm('accounts.print_config')
})
const canRepeatedDishesAndIngredients = computed(() => {
  return dietType.value === 'patient'
})
const canCopyDiet = computed(() => {
  return (
    (['system', 'nfz'].includes(dietType.value) || !copiedDietStatus.value) && !copyingDiet.value
  )
})
const canPasteDiet = computed(() => {
  return ['user', 'patient', 'university'].includes(dietType.value) && copiedDietStatus.value
})
const canShareDiet = computed(() => {
  return ['user', 'patient', 'university'].includes(dietType.value)
})
const canDeleteDiet = computed(() => {
  return ['user', 'patient'].includes(dietType.value) && route.name === 'DietDetailWeek'
})
const lossesActive = computed(() => {
  return diet.value?.data.inevitable_losses.active
})
const showLosses = computed(() => {
  const inevitableLossesActive = diet.value?.data.inevitable_losses.active
  return inevitableLossesActive && !lossesClosed.value
})
const showAllergens = computed(() => {
  return allergensInfo.value && !allergensClosed.value && hasPerm('accounts.patient_allergens')
})
const showIncompleteData = computed(() => {
  return incompleteDataInfo.value && !incompleteDataInfoClosed.value
})
const dietName = computed(() => {
  return diet.value?.data.name ?? 'jadłospis'
})
const userEmail = computed(() => {
  return user?.value?.email ?? ''
})
const printThemes = computed(() => {
  let themes = [
    { value: 'kcalmar', text: 'Podstawowy' },
    { value: 'leaf', text: 'Liść' },
    { value: 'holiday_one', text: 'Świąteczny Śnieżki' },
    { value: 'holiday_two', text: 'Świąteczny Renifer' },
    { value: 'holiday_three', text: 'Świąteczny Klasyczny' },
    { value: 'easter', text: 'Wielkanoc' },
    { value: 'easter_print', text: 'Wielkanoc Druk' },
    { value: 'spring_one', text: 'Wiosna Pierwszy' },
    { value: 'spring_two', text: 'Wiosna Drugi' },
    { value: 'spring_two_print', text: 'Wiosna Drugi Druk' },
    { value: 'autumn', text: 'Jesień' },
    { value: 'banan', text: 'Banan' },
    { value: 'friuts_and_vegetables_one', text: 'Owoce i Warzywa Pierwszy' },
    { value: 'friuts_and_vegetables_two', text: 'Owoce i Warzywa Drugi' },
    { value: 'friuts_and_vegetables_three', text: 'Owoce i Warzywa Trzeci' },
    { value: 'gold_frame', text: 'Złota ramka' },
    { value: 'gold_frame_flowers', text: 'Złota ramka kwiaty' },
    { value: 'gold_frame_leafs', text: 'Złota ramka liście' },
    { value: 'clover_flower', text: 'Kwiat koniczyny' },
    // Master
    { value: 'vege', text: 'Vege' },
    { value: 'for_kids', text: 'Dla dzieci' },
    { value: 'vision_deffect', text: 'Duża czcionka' },
    { value: 'minimal_grey', text: 'Minimal Szary' },
    { value: 'minimal_green', text: 'Minimal Zielony' },
    { value: 'minimal_pink', text: 'Minimal Różowy' },
    { value: 'mediterranean', text: 'Śródziemnorski' },
    // Premium
    { value: 'for_sport', text: 'Dla sportowca' },
    { value: 'rysunki_blue', text: 'Rysunki Niebieski' },
    { value: 'rysunki_green', text: 'Rysunki Zielony' },
    { value: 'rysunki_yellow', text: 'Rysunki Żółty' },
    { value: 'pixel_red', text: 'Pixel Czerwony' },
    { value: 'pixel_orange', text: 'Pixel Pomarańczowy' },
    { value: 'pastel', text: 'Pastelowy' },
    // VIP
    { value: 'pixel_green', text: 'Pixel Zielony' },
    { value: 'cosmos', text: 'Kosmos' },
    { value: 'design_green', text: 'Deseń Zielony' },
    { value: 'design_pink', text: 'Deseń Różowy' },
    { value: 'ingredient_italic', text: 'Składniki Kursywa' },
    { value: 'ingredient_bold', text: 'Składniki Pogrubione' }
  ]
  if (hasPerm('accounts.print_template_15')) themes = themes.slice(0, 39)
  else if (hasPerm('accounts.print_template_11')) themes = themes.slice(0, 34)
  else if (hasPerm('accounts.print_template_10')) themes = themes.slice(0, 33)
  else if (hasPerm('accounts.print_template_5')) themes = themes.slice(0, 26)
  else themes = themes.slice(0, 19)

  if (hasPerm('accounts.diet_nfz')) {
    themes.push(
      { value: 'nfz_leaf_top', text: 'NFZ Liść Góra' },
      { value: 'nfz_leaf_contour', text: 'NFZ Liść Kontur' },
      { value: 'nfz_fruits', text: 'NFZ Owoce' }
    )
  }
  return themes
})
const dietStartDate = computed(() => {
  if (diet.value?.data.start_date.includes('-')) {
    return parse(diet.value.data.start_date, 'dd-MM-yyyy', new Date())
  }
  return null
})
const changeWeekStartDate = computed(() => {
  if (dietStartDate.value) {
    return format(dietStartDate.value, 'dd-MM-yyy')
  }
  return ''
})
const changeWeekEndDate = computed(() => {
  if (dietStartDate.value) {
    return format(addDays(dietStartDate.value, 6), 'dd-MM-yyyy')
  }
  return ''
})

const checkPermissions = () => {
  if (!printConfig.value) {
    showAppUpgradePlanModal()
  }
}
const deactivateSelects = () => {
  eventBus.emit('deactivateSelects')
}
const redirectToPlanChange = () => {
  hidePrintDietModal()
  const url = import.meta.env.VUE_APP_BASE_CLIENT_CREATOR_URL
  window.open(url, '_blank')
}
const handleLossesClosed = (active: boolean) => {
  updateLossesClosedActive(active)
}

const handleIncompleteDataInfoClosed = (active: boolean) => {
  updateIncompleteDataInfoClosedActive(active)
}
// Undo
const undoHandler = () => {
  if (hasPerm('accounts.diet_undo_redo')) {
    undo()
    eventBus.emit('undoOrRedo')
  } else {
    showAppUpgradePlanModal()
  }
}
// Redo
const redoHandler = () => {
  if (hasPerm('accounts.diet_undo_redo')) {
    redo()
    eventBus.emit('undoOrRedo')
  } else {
    showAppUpgradePlanModal()
  }
}
// Assign to leader
const showAssignToLeaderModal = () => {
  assignToLeaderModal.value = true
}
const hideAssignToLeaderModal = () => {
  assignToLeaderModal.value = false
}
// Assign to patient
const showAssignToPatientModal = () => {
  if (isRodoAccepted.value) {
    assignToPatientModal.value = true
  } else {
    location.href = import.meta.env.VUE_APP_HOST + '/dietetyk/account/agreements'
  }
}
const hideAssignToPatientModal = () => {
  assignToPatientModal.value = false
}
// Repeated dishes
const repeatedDishesHandler = () => {
  if (hasPerm('accounts.diet_show_repeated_dish')) {
    toggleRepeatedDishesAndIngredientsActive()
  } else {
    showAppUpgradePlanModal()
  }
}
const isDietVisibleHandler = async () => {
  await toggleIsDietVisible(!isDietVisible.value)
}
const sendSystemDietsListToFile = async () => {
  const payload = {
    type: 'system-diets',
    user: userEmail.value,
    name: dietName.value
  }
  try {
    await dietsService.saveCopiedSystemDietInFile([payload])
  } catch (err) {
    reportError(err, 'Error while saving copied system diet in file', {
      payload
    })
  }
}
// Copy diet
const copyDietHandler = async () => {
  copyingDiet.value = true
  // TODO FIX
  setTimeout(() => {
    copyDiet()
    copyingDiet.value = false
  }, 10)
  if (dietType.value === 'system') {
    await sendSystemDietsListToFile()
  }
}
// Paste diet
const showPasteDietModal = () => {
  pasteDietModalCalories.value = copiedDietCalories.value
  pasteDietModal.value = true
  try {
    const copiedDietFromStorage = localStorage.getItem('copiedDiet') ?? ''
    const copiedDiet = JSON.parse(decompressFromUTF16(copiedDietFromStorage)) as unknown
    if (copiedDiet) {
      print.value.copiedMealsCount = (copiedDiet as StoreDiet).data.meals.length
    }
  } catch (error) {
    reportError(error, 'Error while parsing copied diet from storage')
    print.value.copiedMealsCount = 0
  }
}
const hidePasteDietModal = () => {
  pasteDietModal.value = false
}
const pasteDietModalConfirm = () => {
  pasteDiet(pasteDietModalCalories.value)
  hidePasteDietModal()
}
const pasteDietModalClearStorage = () => {
  deleteCopiedDiet()
  hidePasteDietModal()
}

// Print diet
const showPrintDietModal = () => {
  printDietModal.value = true
  print.value.printInevitableLosses = false
  if (lossesActive.value) {
    print.value.printInevitableLosses = true
  }
  if (diet.value && 'dietitian_patient' in diet.value) {
    const dietitian_patient = diet.value.dietitian_patient
    const pdfDays = dietitian_patient.pdf_days
    print.value.days.monday = pdfDays.includes(0)
    print.value.days.tuesday = pdfDays.includes(1)
    print.value.days.wednesday = pdfDays.includes(2)
    print.value.days.thursday = pdfDays.includes(3)
    print.value.days.friday = pdfDays.includes(4)
    print.value.days.saturday = pdfDays.includes(5)
    print.value.days.sunday = pdfDays.includes(6)

    print.value.groupBy = byDefault(dietitian_patient.pdf_group_by, print.value.groupBy)
    print.value.sortBy = byDefault(dietitian_patient.pdf_sort_by, print.value.sortBy)
    print.value.showWeek = byDefault(dietitian_patient.pdf_show_week, print.value.showWeek)
    print.value.showDate = byDefault(dietitian_patient.pdf_show_date, print.value.showDate)
    print.value.showMealHour = byDefault(
      dietitian_patient.pdf_show_meal_hour,
      print.value.showMealHour
    )
    print.value.showComments = byDefault(
      dietitian_patient.pdf_show_comments,
      print.value.showComments
    )
    print.value.showNutrients = byDefault(
      dietitian_patient.pdf_show_nutrients,
      print.value.showNutrients
    )
    print.value.showChosenNutrients = byDefault(
      dietitian_patient.pdf_show_chosen_nutrients,
      print.value.showChosenNutrients
    )
    print.value.showShoppingList = byDefault(
      dietitian_patient.pdf_show_shopping_list,
      print.value.showShoppingList
    )
    print.value.showQRCodes = byDefault(
      dietitian_patient.pdf_show_qr_codes,
      print.value.showQRCodes
    )
    print.value.showPreparationTime = byDefault(
      dietitian_patient.pdf_show_preparation_time,
      print.value.showPreparationTime
    )
    print.value.separateDaysOrMeals = byDefault(
      dietitian_patient.pdf_separate_days_or_meals,
      print.value.separateDaysOrMeals
    )
    print.value.showKcalmarLogo = byDefault(
      dietitian_patient.pdf_show_kcalmar_logo,
      print.value.showKcalmarLogo
    )
    const themesList: string[] = []
    if (dietitian_patient.theme) {
      Object.entries(printThemes.value).forEach(([, object]) => themesList.push(object.value))
      if (themesList.includes(dietitian_patient.theme)) {
        print.value.theme = dietitian_patient.theme
      }
    }
  }
}
const hidePrintDietModal = () => {
  printDietModal.value = false
  testPrint.value = false
}
const printDietModalConfirm = () => {
  const params = {
    type: 'system',
    theme: encodeURIComponent(print.value.theme),
    group_by: encodeURIComponent(print.value.groupBy),
    sort_by: encodeURIComponent(print.value.sortBy),
    show_week: encodeURIComponent(print.value.showWeek),
    show_date: encodeURIComponent(print.value.showDate),
    show_meal_hour: encodeURIComponent(print.value.showMealHour),
    show_comments: encodeURIComponent(print.value.showComments),
    show_nutrients: encodeURIComponent(print.value.showNutrients),
    show_chosen_nutrients: encodeURIComponent(print.value.showChosenNutrients),
    show_shopping_list: encodeURIComponent(print.value.showShoppingList),
    show_preparation_time: encodeURIComponent(print.value.showPreparationTime),
    show_kcalmar_logo: encodeURIComponent(print.value.showKcalmarLogo),
    show_qr_codes: encodeURIComponent(print.value.showQRCodes),
    print_inevitable_losses: encodeURIComponent(print.value.printInevitableLosses),
    separate_days_or_meals: encodeURIComponent(print.value.separateDaysOrMeals),
    days: encodeURIComponent(
      stringify([
        print.value.days.monday,
        print.value.days.tuesday,
        print.value.days.wednesday,
        print.value.days.thursday,
        print.value.days.friday,
        print.value.days.saturday,
        print.value.days.sunday
      ])
    )
  }

  if (hasPerm('accounts.print_full') || hasPerm('accounts.print_limited')) {
    params.type = dietType.value
  }
  // prepare params dict into url get params
  const paramsToUrl = pipe(
    D.keys(params),
    A.map((paramName) => `${paramName}=${params[paramName]}`),
    A.join('&')
  )
  // build url, start from test and then print full
  let url = `${import.meta.env.VUE_APP_HOST}/pdf/new-diet/648411/?${paramsToUrl}`
  if (hasPerm('accounts.print_test')) {
    url = `${import.meta.env.VUE_APP_HOST}/pdf/new-diet/${
      import.meta.env.VUE_APP_SYSTEM_DIET_ID
    }/?${paramsToUrl}`
  }
  if (hasPerm('accounts.print_full') || hasPerm('accounts.print_limited')) {
    url = `${import.meta.env.VUE_APP_HOST}/pdf/new-diet/${dietId.value}/?${paramsToUrl}`
  }
  window.open(
    url,
    '_blank' // new window or tab.
  )
  incrementPrintedDiets()
  hidePrintDietModal()
}
// Share diet
const showShareDietModal = () => {
  if (hasPerm('accounts.diet_share')) {
    shareDietModal.value = true
  } else {
    showAppUpgradePlanModal()
  }
}
const hideShareDietModal = () => {
  shareDietModal.value = false
}
// Delete diet
const showDeleteDietModal = () => {
  deleteDietModal.value = true
}
const hideDeleteDietModal = () => {
  deleteDietModal.value = false
}
const deleteDietModalConfirm = () => {
  deleteDiet()
  hideDeleteDietModal()
}

const goToDiet = (date: Date, event: MouseEvent) => {
  if (diet.value && 'dietitian_patient' in diet.value) {
    const dietitianPatientId = (diet.value.dietitian_patient as DietitianPatient).id
    const href = `${import.meta.env.VUE_APP_NEXT_PREV_DIET_URL}${dietitianPatientId}/${format(
      date,
      'yyyy/M/d'
    )}/`
    if (event.button === 2) {
      // Middle click
      window.open(href, '_blank')
    } else {
      // Normal click
      location.href = href
    }
  }
}
const goToNextDiet = (event: MouseEvent) => {
  if (dietStartDate.value) {
    const date = addDays(dietStartDate.value, 7)
    goToDiet(date, event)
  }
}
const goToPrevDiet = (event: MouseEvent) => {
  if (dietStartDate.value) {
    const date = subDays(dietStartDate.value, 7)
    goToDiet(date, event)
  }
}
const fetchData = async (resetState = false) => {
  try {
    const res = await dietsService.fetchDiet(
      dietType.value,
      route.params.dietId as unknown as number
    )

    updateDiet({
      diet: res.data,
      dietType: dietType.value,
      resetState
    })
    if (dietType.value === 'patient') {
      const response = await dietsService.fetchPatientDietRepeatedDishes(dietId.value)
      updateRepeatedDishesAndIngredients(response.data)
    }
  } catch (error) {
    reportError(error, 'Error while fetching data', {
      type: dietType.value,
      id: route.params.dietId
    })
    if ((error as AxiosError).response?.status === 404) {
      void router.push({ name: 'PageNotFound' })
    }
  }
}

onBeforeMount(async () => {
  await fetchData()
})

onBeforeUnmount(() => {
  resetDietsStore()
})

watch(
  () => route.params,
  (newParams, oldParams) => {
    if (G.isNotNullable(newParams.dietId) && G.isNotNullable(newParams.dietType)) {
      if (newParams.dietId !== oldParams.dietId && newParams.dietType !== oldParams.dietType) {
        dietId.value = Number(newParams.dietId)
        dietType.value = newParams.dietType as DietType
        void fetchData(true)
      }
    }
  }
)
</script>

<style scoped>
.align-end {
  align-self: flex-end;
}

.diet-detail {
  max-width: 100%;
}

.diet-detail-change-week-save {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  min-width: 300px;
}

.diet-detail-change-week {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.diet-detail-change-week .dates {
  font-size: 12px;
  min-width: 130px;
  white-space: nowrap;
}

.diet-detail-change-week__button.base-button .base-icon {
  color: var(--color-primary-text);
}

.print-modal__diet-limit p {
  font-size: 12px;
  margin: 0;
  max-width: 215px;
}

.print-modal__diet-limit p.print-modal__diet-limit--warning {
  color: red;
}

.nav-actions {
  display: flex;
  flex-direction: row;
}

.nav {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.nav-element {
  padding: 10px 8px;
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  white-space: nowrap;
  color: var(--color-tertiary-text);
  margin-right: 14px;
}

.nav-element:hover {
  color: var(--color-primary-text);
}

.nav-element.router-link-active {
  border-bottom: 4px solid var(--color-main-100);
  color: var(--color-primary-text);
}

.actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 2px;
}

.actions-separator {
  width: 2px;
  height: 18px;
  background: var(--color-tertiary-text);
  margin: 0 10px;
}

.actions__repeated-button.active:hover {
  background: var(--color-warning-30);
}

.actions__repeated-button.active .base-icon {
  color: var(--color-warning-100);
}

.actions__repeated-button.active:hover .base-icon {
  color: #e5aa1d;
}

.allergy-text {
  flex-grow: 1;
  align-self: center;
}

.allergy-info {
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
}

.allergy-info__square {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  line-height: 12px;
}

.allergy-info__square.notlike {
  background: var(--color-warning-60);
}

.allergy-info__square.like {
  background: var(--color-success-60);
}

.allergy-info__square.allergy {
  background: #faa0a8;
}

.allergy-info__square.intolerance {
  background: #a0a8fa;
}

.paste-diet-modal__scale-form {
  display: flex;
  flex-direction: row;
}

.meals-count-info {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.days-container {
  display: flex;
  gap: 20px;
}

.paste-diet-modal__text {
  line-height: 26px;
}

.paste-diet-modal__input {
  width: 60px;
  text-align: center;
}

.input-dummy {
  width: 60px;
  text-align: center;
  height: 22px;
  padding-top: 3px;
  border-bottom: 1px solid var(--color-tertiary-text);
  color: rgb(166 166 166);
}

.input-dummy:hover {
  border-bottom: 1px solid var(--color-main-100);
}

.test-print-warning {
  display: inline-grid;
  margin-top: 24px;
  color: red;
}

.test-print-warning .paragraph {
  margin-bottom: 5px;
}

.paste-diet-modal-clear-storage-button {
  margin-right: auto;
}

.copy-diet-loader {
  margin: 4px;
}

.share-diet-input {
  width: 100%;
  margin-top: 24px;
}

.share-diet-errors {
  padding: 5px;
  color: var(--color-destructive-100);
}

.share-diet-error {
  font-size: 10px;
}

.print-modal-box {
  display: flex;
  flex-direction: column;
}

.print-modal-title {
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: #000;
  margin: 20px 0;
}

.print-modal-theme {
  flex-direction: row;
  align-items: center;
}

.print-modal-theme-title {
  margin: 20px 0 0;
}

.print-modal-theme-select {
  margin: 20px 0 0 4px;
  width: 180px;
}

.print-modal-theme-select :deep(.base-select__options) {
  max-height: 392px;
}

.print-modal-inner-box {
  display: flex;
  gap: 0 20px;
}

.print-modal-inner-box-options {
  display: flex;
  flex-direction: column;
  gap: 12px 0;
}

.base-button--primary.disabled {
  pointer-events: none;
  background-color: #f5f5f5;
  color: #fff;
  border: none;
}
</style>
